import { override } from 'flarum/common/extend';
import BasicEditorDriver from 'flarum/utils/BasicEditorDriver'
import {bbcodeImageToMarkdown, getBbcodeImageCount} from "./function";

const richText = require('@askvortsov-rich-text')

app.initializers.add('ffzhou/flarum-rich-text-extend', () => {
  // 重写RichText ProseMirrorEditorDriver
  if (richText) {
    override(
      richText.proseMirror.ProseMirrorEditorDriver.prototype,
      'insertBetween',
      function (original, start, end, text, escape) {
        // 判断文本中有无bbcode图片
        if (getBbcodeImageCount(text)) {
          // 将bbcode图片转为markdown
          text = bbcodeImageToMarkdown(text);
          escape = false;
        }
        return original(start, end, text, escape)
      }
    )
  }

  // 重写BasicEditorDriver
  override(BasicEditorDriver.prototype, 'insertBetween', function (original, start, end, text) {
    return original(start, end, bbcodeImageToMarkdown(text))
  })
});
