import { extend } from 'flarum/extend';
import HeaderSecondary from 'flarum/components/HeaderSecondary'
import LogInModal from 'flarum/components/LogInModal'
import SessionDropdown from 'flarum/components/SessionDropdown'
import usernameSetting from './usernameSetting'

app.initializers.add('ffzhou/hydrophis-login', () => {
  // 删除注册按钮
  extend(HeaderSecondary.prototype, 'items', function(items) {
    items.remove('signUp');
  });
  // 删除登陆弹窗中的忘记密码和注册
  extend(LogInModal.prototype, 'footer', function(items) {
    items.splice(items.find(v => v.attrs.className === 'LogInModal-forgotPassword'));
    items.splice(items.find(v => v.attrs.className === 'LogInModal-signUp'));
  });
  // 删除修改密码和修改邮箱按钮
  extend(SessionDropdown.prototype, 'items', function(items) {
    if (app.forum.attribute('isHideLogOut')) {
      items.remove('logOut');
    }
  });
  // 用户信息修改设置
  usernameSetting()

});
