import { extend } from 'flarum/common/extend';
import Button from 'flarum/common/components/Button';
import SettingsPage from 'flarum/common/components/SettingsPage';
import ChangeUsernameModal from './components/ChangeUsernameModal';

export default function () {
  extend(SettingsPage.prototype, 'accountItems', function(items) {
    // 删除登陆的设置页中的修改邮箱和修改密码
    items.remove('changePassword');
    items.remove('changeEmail');
    // 新增用户修改按钮
    items.add(
      'hydrophis.changeUsername',
      Button.component(
        {
          className: 'Button',
          onclick: () => {
            app.modal.show(ChangeUsernameModal);
          },
        },
        app.translator.trans('ffzhou-hydrophis-login.forum.settings.username_change_button')
      ),
      8
    );
  });
}
