/**
 * 获取文本中正则图片的数量
 * @param text
 */
export function getBbcodeImageCount(text) {
  let res = text.match(/\[upl-image-preview url=(.*?)]/g);
  return  !res ? 0 : res.length;
}

/**
 * 将文本中的bbcode格式图片转为Markdowb格式图片
 * @param text
 */
export function bbcodeImageToMarkdown(text) {
  // 将bbcode图片替换为markdown图片
  // 通过正则获取bbcode图片 [upl-image-preview url=${file.url()}]
  return text.replace(/\[upl-image-preview url=(.*?)]/g, '![]($1)');
}
