import Stream from 'flarum/common/utils/Stream';
import Modal from 'flarum/common/components/Modal';
import Button from 'flarum/common/components/Button';

export default class ChangeUsernameModal extends Modal {
    oninit(vnode) {
      super.oninit(vnode);
      this.username = Stream(app.session.user.username());
    }

    className() {
      return 'HydrophisUsernameModal Modal--small';
    }

    title() {
      return app.translator.trans('ffzhou-hydrophis-login.forum.username_modals.title');
    }

    content() {
        return (
            <div className="Modal-body">
                <div className="Form Form--centered">
                    <div className="Form-group">
                        <input
                            type="text"
                            name="text"
                            className="FormControl"
                            autocomplete="off"
                            placeholder={app.session.user.username()}
                            bidi={this.username}
                            disabled={this.loading}
                        />
                    </div>
                    <div className="Form-group">
                        {Button.component(
                            {
                                className: 'Button Button--primary Button--block',
                                type: 'submit',
                                loading: this.loading,
                            },
                            app.translator.trans(`ffzhou-hydrophis-login.forum.username_modals.sumbit_button`)
                        )}
                    </div>
                </div>
            </div>
        );
    }

    onsubmit(e) {
      e.preventDefault();

      if (this.username() === app.session.user.username()) {
          this.hide();
          return;
      }

      this.loading = true;

      app.request({
          method: 'POST',
          url: app.forum.attribute('apiUrl') + '/hydrophis-username-change',
          body: { username: this.username() },
      }, {
        errorHandler: this.onerror.bind(this),
      })
      .then((response) => {
        if (response && response.data) {
          app.session.user.pushData(response.data)
          this.hide()
        }
        this.loading = false
      })
      .catch(() => {
        this.loading = false
        m.redraw()
      })
    }
}
